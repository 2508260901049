.head-section-2-box {
    display: flex;
    position: relative;
    background-color: "background.default";
    border-radius: 10px;
    padding: 1%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.head-section-2-button {
    margin-top: 2em !important;
    color: "background.default";
}

.head-section-2-learn-more {
    margin-top: 1em !important;
    text-align: center;
}
.head-section-background-image {
    background-image: url('https://test-image-bucket-fdsjasdfoiew.s3.amazonaws.com/test.png');
    background-size: cover;
}

.head-section-text-box {
    font-size: 1.5em !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px !important;
    font-weight: 450 !important;
    font-family: 'Montserrat'!important;
}

.head-section-text-box-tall {
    height: 8em;
}
